const apiUrl = process.env.API_URL;
if (window.jQuery) {
  (function() {
    (function($) {
      'use strict';
      var getParams, getPostId, main_nav, main_nav_height, nav_sections;
      getParams = function() {
        var key, params, query, raw_vars, v, val, _i, _len, _ref;
        query = window.location.search.substring(1);
        raw_vars = query.split("&");
        params = {};
        for (_i = 0, _len = raw_vars.length; _i < _len; _i++) {
          v = raw_vars[_i];
          _ref = v.split("="), key = _ref[0], val = _ref[1];
          params[key] = decodeURIComponent(val);
        }
        return params;
      };
      getPostId = function() {
        var query, raw_vars;
        query = window.location.pathname;
        raw_vars = query.split("/");
        return raw_vars[raw_vars.length - 1];
      };
      $.ajax({
        url: `${apiUrl}/api/v1/auth/local`,
        type: 'post',
        data: {
          email: 'jameswork514@gmail.com',
          password: '11111111'
        },
        dataType: 'json',
        success: function(res) {
          return $.ajax({
            url: `${apiUrl}/api/v1/posts`,
            type: 'get',
            data: {
              athena: 'one',
              post: getPostId(),
              auth: res._id
            },
            dataType: 'json',
            beforeSend: (function(_this) {
              return function(xhr) {
                return xhr.setRequestHeader("Authorization", "Bearer " + res.token);
              };
            })(this),
            success: (function(_this) {
              return function(data) {
                var i, one, x, _i, _len, _ref;
                if (data[0].user.photo_src) {
                  $(".post-user-avatar").attr('src', data[0].user.photo_src);
                } else {
                  $(".post-user-avatar").attr('src', '/img/default-user.png');
                }
                $(".user-name").html(data[0].user.first_name + ' ' + data[0].user.last_name);
                if (moment.duration(moment().diff(moment(data[0].created))).years() > 0 || moment.duration(moment().diff(moment(data[0].created))).months() > 0 || moment.duration(moment().diff(moment(data[0].created))).days() > 7) {
                  $(".user-time").html(moment(data[0].created).format('YYYY-MM-DD'));
                } else {
                  if (moment.duration(moment().diff(moment(data[0].created))).days() > 1) {
                    $(".user-time").html(moment.duration(moment().diff(moment(data[0].created))).days() + ' days ago');
                  } else {
                    if (moment.duration(moment().diff(moment(data[0].created))).days() === 1) {
                      $(".user-time").html('a days ago');
                    } else {
                      if (moment.duration(moment().diff(moment(data[0].created))).hours() > 1) {
                        $(".user-time").html(moment.duration(moment().diff(moment(data[0].created))).hours() + ' hrs ago');
                      } else {
                        if (moment.duration(moment().diff(moment(data[0].created))).minutes() > 1) {
                          $(".user-time").html(moment.duration(moment().diff(moment(data[0].created))).minutes() + ' minutes ago');
                        } else {
                          if (moment.duration(moment().diff(moment(data[0].created))).minutes() === 1) {
                            $(".user-time").html(moment.duration(moment().diff(moment(data[0].created))).minutes() + ' minute ago');
                          } else {
                            $(".user-time").html('a few seconds ago');
                          }
                        }
                      }
                    }
                  }
                }
                x = "";
                _ref = data[0].data;
                for (i = _i = 0, _len = _ref.length; _i < _len; i = ++_i) {
                  one = _ref[i];
                  if (one.type === 'image') {
                    x = x + ("<div class='block-slick'><img src='" + one.url + "' alt='" + i + "' class='img-fluid' /></div>");
                  } else {
                    x = x + ("<div class='block-slick'><img id='video-play-button' class='post-video-play hide' src='/img/play.png'><video id='post-video' autoplay playsinline muted loop class='img-fluid' style='object-fit: cover;'><source src=" + one.url + " type='video/mp4'/></video></div>");
                  }
                }
                $("#dashboard").html(x);
                $(".post-description").html(data[0].description);
                $(".post-like-button").html("<i class='mr-2'><img src='/img/like.png' alt='like'></i> " + data[0].likes);
                $(".post-comment-button").html("<i class='mr-2'><img src='/img/comment.png' alt='like'></i> " + data[0].comments);
                $(".post-user-button").attr("href", "/user?_id=" + data[0].user._id);
                $('.slider-nav').slick({
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  speed: 350,
                  infinite: true,
                  swipe: true,
                  arrows: false,
                  asNavFor: '.slider-for',
                  dots: false,
                  focusOnSelect: true,
                  responsive: [
                    {
                      breakpoint: 768,
                      settings: {
                        dots: false,
                        swipe: true
                      }
                    }
                  ]
                });
                $('.action a[data-slide]').click(function(e) {
                  var slideno;
                  e.preventDefault();
                  slideno = $(this).data('slide');
                  $('.slider-nav').slick('slickGoTo', slideno - 1);
                  $(this).siblings().removeClass('active');
                  $(this).addClass('active');
                });
                $('.download-popup, .post-like-button, .post-comment-button, .post-share-button, .post-download-button').click(function(e) {
                  var fallbackLink, isAndroid, isiOS, run;
                  isiOS = window.navigator.userAgent.match('iPad') || window.navigator.userAgent.match('iPhone') || window.navigator.userAgent.match('iPod');
                  isAndroid = window.navigator.userAgent.match('Android');
                  fallbackLink = isAndroid ? "https://play.google.com/store/apps/details?id=com.qalorieapp" : "https://apps.apple.com/us/app/qalorie-app/id1538149091";
                  if (isAndroid || isiOS) {
                    window.location = 'qaloriemobile:/' + window.location.pathname;
                    run = function() {
                      return window.location = fallbackLink;
                    };
                    setTimeout(run, 500);
                  }
                  $('#dialog').removeClass('hide');
                  $('#dialog').addClass('show');
                  $('body').addClass('scroll');
                });
                $('.dialog-close').click(function(e) {
                  $('#dialog').removeClass('show');
                  $('#dialog').addClass('hide');
                  $('body').removeClass('scroll');
                });
                $('#post-video').click(function(e) {
                  if ($('#post-video')[0].paused) {
                    $('#post-video')[0].play();
                    $('#video-play-button').removeClass('show');
                    $('#video-play-button').addClass('hide');
                  } else {
                    $('#post-video')[0].pause();
                    $('#video-play-button').removeClass('hide');
                    $('#video-play-button').addClass('show');
                  }
                });
                $('#video-play-button').click(function(e) {
                  $('#post-video')[0].play();
                  $('#video-play-button').removeClass('show');
                  return $('#video-play-button').addClass('hide');
                });
              };
            })(this)
          });
        }
      });
      $(window).scroll(function() {
        if ($(this).scrollTop() > 40) {
          $('.back-to-top').fadeIn('slow');
        } else {
          $('.back-to-top').fadeOut('slow');
        }
      });
      $('.back-to-top').click(function() {
        $('html, body').animate({
          scrollTop: 0
        }, 1000);
        return true;
      });
      (new WOW).init();
      $(window).scroll(function() {
        if ($(this).scrollTop() > 40) {
          $('#header').addClass('header-scrolled');
        } else {
          $('#header').removeClass('header-scrolled');
        }
      });
      if ($(window).scrollTop() > 40) {
        $('#header').addClass('header-scrolled');
      }
      $(window).scroll(function() {
        if ($(this).scrollTop() > 40) {
          $('#header2').addClass('header-scrolled');
        } else {
          $('#header2').removeClass('header-scrolled');
        }
      });
      if ($(window).scrollTop() > 40) {
        $('#header2').addClass('header-scrolled');
      }
      $('.main-nav a, .mobile-nav a, .scrollto, .footer-menu li a').on('click', function() {
        var target, top_space;
        if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
          target = $(this.hash);
          if (target.length) {
            top_space = 0;
            if ($('#header').length) {
              top_space = $('#header').outerHeight();
              if (!$('#header').hasClass('header-scrolled')) {
                top_space = top_space - 40;
              }
            }
            if ($('#header2').length) {
              top_space = $('#header2').outerHeight();
              if (!$('#header2').hasClass('header-scrolled')) {
                top_space = top_space - 40;
              }
            }
            $('html, body').animate({
              scrollTop: target.offset().top - top_space
            }, 1000);
            if ($(this).parents('.main-nav, .mobile-nav, .footer-menu').length) {
              $('.main-nav .active, .mobile-nav .active, .footer-menu .active ').removeClass('active');
              $(this).closest('li').addClass('active');
            }
            if ($('body').hasClass('mobile-nav-active')) {
              $('body').removeClass('mobile-nav-active');
              $('.mobile-nav-toggle i').toggleClass('fa-close hamburger_icon');
              $('.mobile-nav-overly').fadeOut();
            }
            return false;
          }
        }
      });
      nav_sections = $('section');
      main_nav = $('.main-nav, .mobile-nav, .footer-menu');
      main_nav_height = $('#header').outerHeight();
      main_nav_height = $('#header2').outerHeight();
      return $(window).on('scroll', function() {
        var cur_pos;
        cur_pos = $(this).scrollTop();
        nav_sections.each(function() {
          var bottom, top;
          top = $(this).offset().top - main_nav_height;
          bottom = top + $(this).outerHeight();
          if (cur_pos >= top && cur_pos <= bottom) {
            main_nav.find('li').removeClass('active');
            main_nav.find('a[href="#' + $(this).attr('id') + '"]').parent('li').addClass('active');
          }
        });
      });
    })(jQuery);

  }).call(this);
}
